import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDeliverdThunk } from "../store/chatBotCreatorThunk";
import { Card } from "react-bootstrap";
import { Button, CardContent } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";

function DesignCard({ item, index }) {
  return (
    <Card
      key={index}
      className="card-border my-2"
      style={{
        borderRadius: "16px",
        padding: "1rem",
      }}
      fullWidth
    >
      <CardContent sx={{ padding: ".8rem !important" }} key={index}>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-4">
                    <div className="row">
                      <div className="mb-3">
                        <b> Sr.No</b>
                      </div>
                      <div className="sub-heading">
                        <b> {index + 1}</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mb-3">
                    <div className="row">
                      <div className="col-12  mb-3">
                        <b>CourierName</b>
                      </div>
                    </div>
                    <div className="sub-heading">{item?._id}</div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col-6  mb-3">
                        <b> Total</b>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  mb-3">
                        <div className="sub-heading">{item?.total}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-6"
            style={{
              minHeight: "100px",
              maxHeight: "200px",
              overflowY: "scroll",
            }}
          >
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-6 mb-3">
                    <b>OrderId</b>
                  </div>
                </div>
                {item?.orderId?.map((element, index) => (
                  <div className="row">
                    <div className="col-6 mb-3">
                      <b>{index + 1}.</b>&nbsp;{element}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6 mb-3">
                    <b>AWB</b>
                  </div>
                </div>

                {item?.awb?.map((element, index) => (
                  <div className="row">
                    <div className="col-6 mb-3">
                      <b></b>&nbsp;{element}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function OrderDeliveredComponentes() {
  const date = new Date();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(date.setDate(Number(date.getDate() + 1)))
  );

  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  

  const onClickSearch = () => {
    dispatch(
      getOrderDeliverdThunk({
        JWT: state.loginToken,
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  function generateCSV(data) {
    let csv = [];

    let header = ["Sr. No.", "Courier Name", "Total", "Order ID", "AWB"];
    csv.push(header.join(","));

    data.forEach((item, index) => {
      let baseRow = [];

      let maxOrderLength = item.orderId ? item.orderId.length : 0;
      let maxAwbLength = item.awb ? item.awb.length : 0;
      let maxLength = Math.max(maxOrderLength, maxAwbLength);

      for (let i = 0; i < maxLength; i++) {
        let row = [...baseRow];
        if (i === 0) {
          row.push(index + 1, item._id, item.total);
        } else {
          row.push("", "", "");
        }
        row.push(item.orderId && item.orderId[i] ? item.orderId[i] : "");
        row.push(item.awb && item.awb[i] ? item.awb[i] : "");

        csv.push(row.join(","));
      }
    });
    return csv.join("\r\n");
  }

  function downloadCSV() {
    const data = state.OrderDeliverd;
    const csvData = generateCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    // Function to dispatch the thunk
    const fetchOrders = () => {
      dispatch(
        getOrderDeliverdThunk({
          JWT: state.loginToken,
          startDate: startDate,
          endDate: endDate,
        })
      );
    };

    // Call it initially
    fetchOrders();

    // Set up the interval
    const intervalId = setInterval(fetchOrders, 5000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch, state.loginToken, startDate, endDate]);

  return (
    <>
      <div className="row d-flex flex-row ">
        <div className="col-8 d-flex flex-row justify-content-start">
          <div className="col-3 text-primary">
            <b className="h6"> Start Date :</b>
            <div>
              <DatePicker
                selectsStart
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                startDate={startDate}
                placeholderText="Select start date"
              />
            </div>
          </div>
          <div className="col-3 text-primary">
            <b className="h6"> End Date : </b>
            <div>
              <DatePicker
                selectsEnd
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                endDate={endDate}
                startDate={startDate}
                minDate={startDate}
                placeholderText="Select end date"
              />
            </div>
          </div>
          <div className="col-1 mt-2">
            <Button
              type="submit"
              variant="outlined"
              size="small"
              startIcon={<SearchIcon />}
              onClick={onClickSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="col-4 mt-2  d-flex flex-row justify-content-end">
          <Button
            variant="outlined"
            size="small"
            startIcon={<DownloadIcon />}
            type="submit"
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        </div>
      </div>
      <hr></hr>
      <div className="col-12" style={{ height: "90%", overflowY: "scroll" }}>
        <div className="row">
          {state.showGetOrdersDeliverdLoader === "" ? (
            <div className="centered" style={{ height: "300px" }}>
              <div className="spinner-border mx-2"></div>
              <span>Loading Order Deliverd...</span>
            </div>
          ) : (
            <div style={{ height: "90%" }}>
              {state.OrderDeliverd.length === 0 ? (
                <span>No Deliverd Order Found</span>
              ) : state.getOrdersDeliverdErrorMessage === "" ? (
                state.OrderDeliverd?.map((item, index) => (
                  <div
                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                    key={index}
                  >
                    <DesignCard index={index} item={item} />
                  </div>
                ))
              ) : (
                <p className="text-danger mt-1">
                  {state.getOrdersDeliverdErrorMessage}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default OrderDeliveredComponentes;
