import React from "react";
import { useSelector } from "react-redux";
import ActionPanel from "./ActionPanel";
import ListDesignsCpmponent from "../ListDesignsComponent";
import NewInventoryComponent from "../NewInventoryComponent";
import ListInventoryComponent from "../ListInventoryComponent";
import NewUserComponent from "../NewUserComponent";
import ListUsersComponent from "../ListUsersComponent";
import NewOrderComponent from "../NewOrderComponent";
import NewRackComponent from "../NewRackComponent";
import ListRacksComponents from "../ListRacksComponents";
import NewPackageComponent from "../NewPackageComponent";
import PendingOrdersComponents from "../PendingOrdersComponents";
import CompletedOrdersComponents from "../CompletedOrdersComponents";
import OrderDeliveredComponentes from "../OrderDeliveredComponenets";
import GetCustomOrdersComponets from "../GetCustomOrdersComponets";
import PendingByEmployeeComponent from "../PendingByEmployeeComponent";
import ReturnOrderComponents from "../ReturnOrdersComponents";
import PaymentComponent from "../PaymentComponents";
import ScannedOrdersDataComponents from "../DispatchedOrdersComponents";
import DispatchedOrdersComponents from "../DispatchedOrdersComponents";

export default function Blade({ botAction }) {
  const state = useSelector((state) => state.chatBotCreatorStore);
  let currentBotActionName = botAction.botActionName.find(
    (ele) => ele.locale === state.currentLocale
  );

  return (
    <div className={`blade mx-1 ${botAction.isLastBlade ? "last-blade" : ""}`}>
      <div className="start-card-header between px-2">
        <div className="blade-header-title node-title">
          {currentBotActionName.text}
        </div>
      </div>

      {/* {botAction.botActionId === "new_design" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <NewDesignComponent />
          </div>
        )} */}

      {botAction.botActionId === "list_designs" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <ListDesignsCpmponent />
          </div>
        )}

      {botAction.botActionId === "new_inventory" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <NewInventoryComponent />
          </div>
        )}

      {botAction.botActionId === "list_inventory" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <ListInventoryComponent />
          </div>
        )}

      {botAction.botActionId === "new_user" && botAction.isVisibleOnScreen && (
        <div className="blade-body-items">
          <NewUserComponent />
        </div>
      )}

      {botAction.botActionId === "list_users" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <ListUsersComponent />
          </div>
        )}

      {botAction.botActionId === "new_order" && botAction.isVisibleOnScreen && (
        <div className="blade-body-items">
          <NewOrderComponent />
        </div>
      )}

      {botAction.botActionId === "pending_orders" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <PendingOrdersComponents />
          </div>
        )}

      {botAction.botActionId === "pending_orders_by_employee" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <PendingByEmployeeComponent />
          </div>
        )}

      {botAction.botActionId === "completed_orders" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <CompletedOrdersComponents />
          </div>
        )}

      {botAction.botActionId === "return_orders" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <ReturnOrderComponents />
          </div>
        )}
      {/* {botAction.botActionId === "dispatch_orders" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <DispatchOrderComponents />
          </div>
        )} */}

      {botAction.botActionId === "new_rack" && botAction.isVisibleOnScreen && (
        <div className="blade-body-items">
          <NewRackComponent />
        </div>
      )}

      {botAction.botActionId === "list_racks" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <ListRacksComponents />
          </div>
        )}

      {botAction.botActionId === "new_package" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <NewPackageComponent />
          </div>
        )}
      {botAction.botActionId === "completed_packages" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <CompletedOrdersComponents />
          </div>
        )}
      {botAction.botActionId === "orders_dispatched" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <OrderDeliveredComponentes />
          </div>
        )}
      

      {botAction.botActionId === "custom_orders" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <GetCustomOrdersComponets />
          </div>
        )}
      {botAction.botActionId === "payments" &&
        botAction.isVisibleOnScreen && (
          <div className="blade-body-items">
            <PaymentComponent />
          </div>
        )}
      <div className="scrollable-div">
        <div className="blade-body">
          <ActionPanel botAction={botAction} />
        </div>
      </div>
    </div>
  );
}
