import { Sync } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CircularProgress,
  Pagination,
  TextField,
} from "@mui/material";
import axios from "axios";
import _debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getAllReturnOrdersThunk } from "../store/chatBotCreatorThunk";

function OrderCard({ order, index, currentPage }) {
  const getRTOStatus = (rtoDetails) => {
    if (rtoDetails?.rtoInitialized === true) {
      return {
        status: "RTO INITIATED",
        className: "text-warning h5 text-primary",
      };
    } else if (rtoDetails?.rtoDelivered === true) {
      return {
        status: "NOT RECEIVED",
        className: "h5 text-primary ",
      };
    } else if (rtoDetails?.rtoReceived === true) {
      return {
        status: "RECEIVED",
        className: "text-success h5 text-primary",
      };
    } else {
      return {
        status: "UNKNOWN",
        className: "text-error h5 text-primary",
      };
    }
  };

  const getRTODate = (order) => {
    if (order.rtoDetails && order.rtoDetails.rtoReceived === true) {
      return moment(order.rtoDetails.rtoReceivedDate).format("DD MMM, YYYY");
    } else if (
      order.rtoDetails &&
      order.rtoDetails.rtoReceived === false &&
      order.rtoDetails.rtoInitializedDate
    ) {
      return moment(order.rtoDetails.rtoInitializedDate).format("DD MMM, YYYY");
    }
    return moment(order.createdDateTime).format("DD MMM, YYYY");
  };

  const getRTOTime = (order) => {
    if (order.rtoDetails && order.rtoDetails.rtoReceived === true) {
      return moment(order.rtoDetails.rtoReceivedDate).format("hh:mm a");
    } else if (
      order.rtoDetails &&
      order.rtoDetails.rtoReceived === false &&
      order.rtoDetails.rtoInitializedDate
    ) {
      return moment(order.rtoDetails.rtoInitializedDate).format("hh:mm a");
    }
    return moment(order.createdDateTime).format("hh:mm a");
  };

  return (
    <Card className="card-border mb-3" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="d-flex align-items-start justify-content-between">
          {/* Serial Number with Vertical Line */}
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              minWidth: "50px",
              borderRight: "2px solid #ccc",
              paddingRight: "10px",
              marginRight: "10px",
            }}
          >
            <div className="small-font">Sr. No.</div>
            <b className="text-primary">{(currentPage - 1) * 10 + index + 1}</b>
          </div>

          {/* Rest of the Content */}
          <div className="row w-100">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-3">
                  <div className="small-font">Order Id</div>
                  <b className="text-primary">{order.orderId}</b>
                  <br />
                  <label className="small-font text-bold">
                    {getRTODate(order)}
                  </label>
                  &nbsp;
                  <label className="small-font text-bold">
                    {getRTOTime(order)}
                  </label>
                </div>
                <div className="col-2">
                  <div className="small-font">Price</div>
                  <b className="text-primary small-font">
                    Rs {order.totalPrice}
                  </b>
                  <br />
                </div>
                <div className="col-2">
                  <div className="small-font">AWB Number</div>
                  <b className="text-primary">{order.awb}</b>
                  <br />
                </div>
                <div className="col-2">
                  <div className="small-font">Channel</div>
                  <b className="text-success">
                    {order?.channel_name || "SHOPIFY"}
                  </b>
                  <br />
                </div>
                <div className="col-3">
                  <div className="small-font">RTO Status:</div>
                  <div className={getRTOStatus(order.rtoDetails).className}>
                    {getRTOStatus(order.rtoDetails).status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
const AlertComponent = ({ message, status, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose(); // Call onClose after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onClose]);

  if (!visible) return null;

  const styles = {
    alert: {
      position: "fixed",
      top: "20px",
      right: "20px",
      zIndex: 1000,
      minWidth: "300px",
      padding: "15px 20px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      fontFamily: "Arial, sans-serif",
      color: "white",
    },
    success: {
      backgroundColor: "#28a745",
    },
    error: {
      backgroundColor: "#dc3545",
    },
    warning: {
      backgroundColor: "#ffc107",
      color: "#212529",
    },
    closeBtn: {
      background: "none",
      border: "none",
      fontSize: "16px",
      fontWeight: "bold",
      cursor: "pointer",
      color: "inherit",
      marginLeft: "10px",
    },
  };

  const getStatusStyle = () => {
    switch (status) {
      case "success":
        return styles.success;
      case "error":
        return styles.error;
      case "warning":
        return styles.warning;
      default:
        return {};
    }
  };

  return (
    <div style={{ ...styles.alert, ...getStatusStyle() }}>
      <span>{message}</span>
      <button style={styles.closeBtn} onClick={() => setVisible(false)}>
        &times;
      </button>
    </div>
  );
};

function ReturnOrderComponents() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const [currentPage, setCurrentPage] = useState(1);
  const [awb, setAWB] = useState("");
  const [searchedOrderID, setSearchedOrderID] = useState("");

  const today = moment();
  const firstDayOfMonth = moment().startOf("month");
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(today);
  const [status, setStatus] = useState("NOT RECEIVED");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    status: "",
  });

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleStartDateChange = (date) => {
    const momentDate = moment(date);
    if (momentDate.isAfter(moment(endDate))) return;
    setStartDate(momentDate);
  };

  const handleEndDateChange = (date) => {
    const momentDate = moment(date);
    if (momentDate.isBefore(moment(startDate))) return;
    setEndDate(momentDate);
  };

  const getAllReturnOrders = () => {
    dispatch(
      getAllReturnOrdersThunk({
        JWT: state.loginToken,
        currentPage,
        awb,
        orderId: searchedOrderID,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        pageSize: 10,
        status,
      })
    );
  };

  const handleFetchRTO = async () => {
    try {
      setLoading(true);
      const { REACT_APP_SERVER_URL } = process.env;
      const JWT = state.loginToken;
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}/order/v1/fetch-rto-orders-from-shiprocket`,
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
          },
        }
      );
      setLoading(false);
      setAlert({
        show: true,
        message: response.data.message || "Success!",
        status: "success",
      });
      return response.data.message;
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setAlert({
          show: true,
          message: error.response.data.message || "Success!",
          status: "error",
        });
        throw error.response.data.message;
      } else {
        setAlert({
          show: true,
          message: "Something Went Wrong!",
          status: "error",
        });
        console.log("Server Not Reachable");
      }
    }
  };

  useEffect(() => {
    getAllReturnOrders();
  }, [startDate, endDate, status, currentPage]);

  // Debounced Search Function
  const debouncedSearch = useCallback(
    _debounce((value, field) => {
      dispatch(
        getAllReturnOrdersThunk({
          JWT: state.loginToken,
          currentPage,
          awb: field === "AWB" ? value : "",
          orderId: field === "Order ID" ? value : "",
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          pageSize: 10,
          status,
        })
      );
    }, 1000),
    [dispatch, state.loginToken, currentPage, startDate, endDate, status]
  );

  // Clear pending debounce calls when inputs are empty
  useEffect(() => {
    if (searchedOrderID) {
      debouncedSearch(searchedOrderID, "Order ID");
    } else {
      debouncedSearch.cancel(); // Cancel pending debounce
    }
  }, [searchedOrderID, debouncedSearch]);

  useEffect(() => {
    if (awb) {
      debouncedSearch(awb, "AWB");
    } else {
      debouncedSearch.cancel(); // Cancel pending debounce
    }
  }, [awb, debouncedSearch]);

  return (
    <>
      <div
        className="row mb-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          gap: "15px",
        }}
      >
        {/* Search By Order ID */}
        <div style={{ flex: 1 }}>
          <TextField
            label="Search By Order ID"
            size="small"
            value={searchedOrderID}
            onChange={(e) => setSearchedOrderID(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getAllReturnOrders();
              }
            }}
            fullWidth
          />
        </div>

        {/* Search By AWB Number */}
        <div style={{ flex: 1 }}>
          <TextField
            label="Search By AWB Number"
            size="small"
            value={awb}
            onChange={(e) => setAWB(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getAllReturnOrders();
              }
            }}
            fullWidth
          />
        </div>

        {/* Start Date */}
        <div style={{ flex: 1 }}>
          <b className="h6">Start Date:</b>
          <DatePicker
            selectsStart
            selected={startDate.toDate()}
            onChange={handleStartDateChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={moment().toDate()}
            placeholderText="Select start date"
            dateFormat={"dd-MM-yyyy"}
          />
        </div>

        {/* End Date */}
        <div style={{ flex: 1 }}>
          <b className="h6">End Date:</b>
          <DatePicker
            selectsEnd
            selected={endDate.toDate()}
            onChange={handleEndDateChange}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate.toDate()}
            maxDate={moment().toDate()}
            placeholderText="Select end date"
            dateFormat={"dd-MM-yyyy"}
          />
        </div>

        {/* RTO Status */}
        <div style={{ flex: 1 }}>
          <b className="h6">RTO Status:</b>
          <select
            className="col-12"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            style={{ height: "30px" }}
          >
            <option value="RTO INITIATED">RTO INITIATED</option>
            <option value="NOT RECEIVED">NOT RECEIVED</option>
            <option value="RECEIVED">RECEIVED</option>
          </select>
        </div>
        <div style={{ flex: 1 }}>
          <button
            onClick={handleFetchRTO}
            style={{
              color: "white",
              backgroundColor: "#007bff",
              border: "none",
              borderRadius: "5px",
              padding: "10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {" "}
            {loading ? (
              <>
                <CircularProgress color="white" size={"25px"} /> Fetching RTO...
              </>
            ) : (
              <>
                <Sync /> Fetch RTO
              </>
            )}
          </button>{" "}
        </div>
      </div>

      {state.getAllReturnOrdersErrorMessage === "" ? (
        <>
          <div
            className="col-12"
            style={{ height: "94%", overflowY: "scroll" }}
          >
            {state.showGetAllReturnOrdersLoader ? (
              <div className="centered" style={{ height: "300px" }}>
                <div className="spinner-border mx-2"></div>
                <span>Loading RTO Orders...</span>
              </div>
            ) : state.returnOrders?.length === 0 ? (
              <h6>No RTO Orders Found.</h6>
            ) : (
              state.returnOrders?.map((order, index) => (
                <OrderCard
                  order={order}
                  index={index}
                  currentPage={currentPage}
                />
              ))
            )}
          </div>
          <div>
            {alert.show && (
              <AlertComponent
                message={alert.message}
                status={alert.status}
                onClose={setAlert({
                  show: false,
                  message: "",
                  status: "",
                })}
              />
            )}
          </div>
        </>
      ) : (
        <p className="text-danger centered">
          {state.getAllReturnOrdersErrorMessage}
        </p>
      )}

      {state.returnOrders?.length > 0 && (
        <div
          className="mb-3 d-flex justify-content-center"
          style={{ position: "fixed", right: 10, bottom: 2 }}
        >
          <Pagination
            count={Math.ceil(state.returnOrdersTotalCount / 10)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default ReturnOrderComponents;
