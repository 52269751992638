import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { getAllCustomeOrdersThunk } from "../store/chatBotCreatorThunk";
import { Button } from "@mui/material";

const GetCustomOrdersComponets = () => {
  const date = new Date();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(date.setDate(Number(date.getDate() + 1))));

  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const onClickCustomSearch = () => {
    dispatch(getAllCustomeOrdersThunk({ JWT: state.loginToken, startDate: startDate, endDate: endDate }));
  };


  return (
    <>
      <div style={{ height: "20%" }} className="row d-flex flex-row ">
        <div className="col-10 d-flex flex-row justify-content-start">
          <div className="col-3 text-primary">
            <b className="h6"> Start Date :</b>
            <div>
              <DatePicker  selectsStart selected={startDate} onChange={(date) => setStartDate(date)} startDate={startDate} placeholderText="Select start date" />
            </div>
          </div>
          <div className="col-3 text-primary">
            <b className="h6"> End Date : </b>
            <div>
              <DatePicker selectsEnd selected={endDate} onChange={(date) => setEndDate(date)} endDate={endDate} startDate={startDate} minDate={startDate} placeholderText="Select end date" />
            </div>
          </div>
          <div className="col-2 mt-2 d-flex align-items-end">
            <Button variant="contained" size="small" startIcon={<SearchIcon />} onClick={onClickCustomSearch}>
              Search
            </Button>
          </div>
        </div>
      </div>

      <hr />

      <div style={{ height: "80%", overflowY: "scroll", overflowX: "none" }}>
        {state.showGetAllCustomLoader ? (
          <div className="d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
            <div className="spinner-border text-secondary mx-2"></div>
            <span>Fetching custom order's...</span>
          </div>
        ) : state.getCustomeOrderErrorMessage === "" ? (
          state.getcustomeOrdermessage ? (
            <div className="centered text-success fs-5"> {state.getcustomeOrdermessage} </div>
          ) : (
            <p className="d-flex  align-items-center justify-content-center">FETCH All CUSTOM Orders</p>
          )
        ) : (
          <p className="text-danger d-flex align-items-center justify-content-center">{state.getCustomeOrderErrorMessage}</p>
        )}
      </div>
    </>
  );
};

export default GetCustomOrdersComponets;
