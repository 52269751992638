import {
  faAddressCard,
  faBoxOpen,
  faBoxesPacking,
  faBoxesStacked,
  faCartFlatbedSuitcase,
  faCartPlus,
  faCartShopping,
  faListCheck,
  faListSquares,
  faMoneyBill,
  faShirt,
  faUser,
  faUserEdit,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Blade from "../../components/chatbotCreaterComponents/Blade";
import loginLogo from "../../logo/bmn_-removebg-preview.webp";
import {
  addNewAction,
  addNewBotActionToBladeActions,
  onInitialize,
} from "../../store/chatBotCreatorSlice";

export default function ChatBotCreatorScreen() {
  const state = useSelector((state) => state.chatBotCreatorStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onInitialize());

    // -----------------------------------------  Inventory ---------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Inventory",
        actionId: "inventory",
        role: ["inventory", "admin"],
        icon: faCartFlatbedSuitcase,
      })
    );

    // -----------------------------------------  Inventory ---------------------------------------------------------

    // -----------------------------------------  Designs ---------------------------------------------------------
    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Designs",
        actionId: "designs",
        role: ["admin"],
        icon: faShirt,
      })
    );
    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "designs",
        isLastBlade: false,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "designs",
    //     actionName: "New Design",
    //     actionId: "new_design",
    //     role: ["admin"],
    //     icon: faPenToSquare,
    //   })
    // );

    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "designs",
    //     childActionId: "new_design",
    //     isLastBlade: true,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );

    dispatch(
      addNewAction({
        parentBotActionId: "designs",
        actionName: "List Designs",
        actionId: "list_designs",
        role: ["admin"],
        icon: faListSquares,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "designs",
        childActionId: "list_designs",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // -----------------------------------------  Designs ---------------------------------------------------------

    // -----------------------------------------  Racks ---------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Racks",
        actionId: "racks",
        role: ["admin"],
        icon: faWarehouse,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "racks",
        isLastBlade: false,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "racks",
        actionName: "New Rack",
        actionId: "new_rack",
        role: ["admin"],
        icon: faBoxesStacked,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "racks",
        childActionId: "new_rack",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "racks",
        actionName: "List Racks",
        actionId: "list_racks",
        role: ["admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "racks",
        childActionId: "list_racks",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // -----------------------------------------  Racks ---------------------------------------------------------

    // -----------------------------------------  Orders ---------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Orders",
        actionId: "orders",
        role: ["admin"],
        icon: faCartShopping,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "orders",
        isLastBlade: false,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "orders",
    //     actionName: "New Order",
    //     actionId: "new_order",
    //     role: ["admin"],
    //     icon: faCartPlus,
    //   })
    // );

    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "orders",
    //     childActionId: "new_order",
    //     isLastBlade: true,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );

    dispatch(
      addNewAction({
        parentBotActionId: "orders",
        actionName: "New Orders",
        actionId: "pending_orders",
        role: ["admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "orders",
        childActionId: "pending_orders",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "orders",
        actionName: "Pending By Employee",
        actionId: "pending_orders_by_employee",
        role: ["admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "orders",
        childActionId: "pending_orders_by_employee",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "orders",
        actionName: "Completed Orders",
        actionId: "completed_orders",
        role: ["admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "orders",
        childActionId: "completed_orders",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // ----------------------------------------- RTO Orders ---------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "orders",
        actionName: "Return To Origin Orders",
        actionId: "return_orders",
        role: ["admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "orders",
        childActionId: "return_orders",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // ----------------------------------------- RTO Orders ---------------------------------------------------------

    // -----------------------------------------  Packaging ------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Packaging",
        actionId: "packaging",
        role: ["packager", "admin"],
        icon: faBoxesPacking,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "packaging",
        isLastBlade: false,
        isInitialize: true,
        role: ["packager", "admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "packaging",
        actionName: "New Package",
        actionId: "new_package",
        role: ["admin", "packager"],
        icon: faBoxOpen,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "packaging",
        childActionId: "new_package",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin", "packager"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "packaging",
        actionName: "Completed packages",
        actionId: "completed_packages",
        role: ["admin", "packager"],
        icon: faBoxOpen,
      })
    );
    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "packaging",
        childActionId: "completed_packages",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin", "packager"],
      })
    );

    // -----------------------------------------  Packaging ------------------------------------------------------

    // -----------------------------------------  Users ------------------------------------------------------

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Users",
        actionId: "users",
        role: ["admin"],
        icon: faUser,
      })
    );
    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "users",
        isLastBlade: false,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "users",
        actionName: "New User",
        actionId: "new_user",
        role: ["admin"],
        icon: faUserEdit,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "users",
        childActionId: "new_user",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );
    dispatch(
      addNewAction({
        parentBotActionId: "users",
        actionName: "List Users",
        actionId: "list_users",
        role: ["admin"],
        icon: faAddressCard,
      })
    );
    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "users",
        childActionId: "list_users",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Orders Dispatched",
        actionId: "orders_dispatched",
        role: ["admin"],
        icon: faCartShopping,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "orders_dispatched",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "main_menu",
    //     actionName: "Orders Delivered",
    //     actionId: "Orders Delivered",
    //     role: ["admin"],
    //     icon: faWarehouse,
    //   })
    // );

    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "main_menu",
    //     childActionId: "Orders Delivered",
    //     isLastBlade: false,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );

    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "Orders Delivered",
    //     actionName: "Orders Delivered",
    //     actionId: "orders_delivered",
    //     role: ["admin"],
    //     icon: faBoxesStacked,
    //   })
    // );

    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "Orders Delivered",
    //     childActionId: "orders_delivered",
    //     isLastBlade: true,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );

    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "Orders Delivered",
    //     actionName: "Orders Dispatched",
    //     actionId: "scanned_orders",
    //     role: ["admin"],
    //     icon: faListCheck,
    //   })
    // );

    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "Orders Delivered",
    //     childActionId: "scanned_orders",
    //     isLastBlade: true,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );


    //------------------------------------------------custom orders ------------------------------------------
    dispatch(
      addNewAction({
        parentBotActionId: "main_menu",
        actionName: "Fetch Orders",
        actionId: "custom_orders",
        role: ["admin"],
        icon: faCartShopping,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "custom_orders",
        isLastBlade: true,
        isInitialize: true,
        role: ["admin"],
      })
    );

    //------------------------------------------------Payment  ------------------------------------------
 
    // dispatch(
    //   addNewAction({
    //     parentBotActionId: "main_menu",
    //     actionName: "Payment",
    //     actionId: "payments",
    //     role: ["admin"],
    //     icon: faMoneyBill,
    //   })
    // );
    // dispatch(
    //   addNewBotActionToBladeActions({
    //     parentBotActionId: "main_menu",
    //     childActionId: "payments",
    //     isLastBlade: true,
    //     isInitialize: true,
    //     role: ["admin"],
    //   })
    // );

    // -----------------------------------------  Users ------------------------------------------------------
    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "main_menu",
        childActionId: "inventory",
        isLastBlade: false,
        isInitialize: true,
        role: ["inventory", "admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "inventory",
        actionName: "New Inventory",
        actionId: "new_inventory",
        role: ["inventory", "admin"],
        icon: faCartPlus,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "inventory",
        childActionId: "new_inventory",
        isLastBlade: true,
        isInitialize: true,
        role: ["inventory", "admin"],
      })
    );

    dispatch(
      addNewAction({
        parentBotActionId: "inventory",
        actionName: "List Inventory",
        actionId: "list_inventory",
        role: ["inventory", "admin"],
        icon: faListCheck,
      })
    );

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: "inventory",
        childActionId: "list_inventory",
        isLastBlade: true,
        isInitialize: true,
        role: ["inventory", "admin"],
      })
    );




  }, []);

  const onClickLogout = () => {
    window.open("/", "_self");
  };

  useEffect(() => {
    if (state.loginToken === "") {
      navigate("/");
    }
  }, []);

  return (
    <div className="main-body-content container-fluid">
      <div className="bot-creator-header align-items-center">
        <label className="project-name">
          <img src={loginLogo} alt="Punekar Cotton" height={70} />
        </label>

        <label className="organization-name">
          <b className="mt-1">{state.organizationName}</b>
        </label>

        <div>
          <Button
            onClick={onClickLogout}
            variant="text hoverable-btn"
            size="small"
            className="mx-2 text-danger"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </div>
      </div>

      <div className="main-body">
        {state.currentBot?.actions?.map(
          (botAction, key) =>
            botAction.isVisibleOnScreen &&
            botAction.role?.includes(state.userRole) && (
              <Blade botAction={botAction} key={key} />
            )
        )}
      </div>
    </div>
  );
}
